<template>
  <div class="row ma-0 pa-0">
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-left">
      <span class="form-title">Dados cadastrais</span>

      <div class="row">
        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strNome"
          name="strNome"
          label="Equipamento"
          required
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strModelo"
          name="strModelo"
          label="Modelo"
          required
          rules="required"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          v-model="formData.strFabricante"
          name="strFabricante"
          label="Fabricante"
          required
          rules="required"
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strDataUltimaAfericao"
          name="strDataUltimaAfericao"
          label="Data Última Aferição"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.intTempoAfericao"
          name="intTempoAfericao"
          label="Tempo de Aferição (em meses)"
          type="number"
          info="Informar a quantidade de meses a partir da data de aferição que sejam válidos para o aparelho em questão."
        />

        <ed-input-date
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.strDataUltimaCalibragem"
          name="strDataUltimaCalibragem"
          label="Data Última Calibragem"
        />

        <ed-input-text
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.intTempoCalibragem"
          name="intTempoCalibragem"
          label="Tempo de Calibragem (em meses)"
          type="number"
          info="Informar a quantidade de meses a partir da data de calibragem que sejam válidos para o aparelho em questão."
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.floatValorLimiteViaAera"
          name="floatValorLimiteViaAera"
          label="Limites - Via Aérea"
          info="Informar o valor limite em decibéis da via aérea conforme consta no aparelho de medição. Ex.: 130."
        />

        <ed-input-money
          class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"
          v-model="formData.floatValorLimiteViaOssea"
          name="floatValorLimiteViaOssea"
          label="Limites - Via Óssea"
          info="Informar o valor limite em decibéis da via óssea conforme consta no aparelho de medição. Ex.: 130."
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  EdInputText,
  EdInputMoney,
  EdInputDate
} from "@/components/common/form";

export default {
  name: "pageCadastroFuncao",
  props: {
    intFuncaoId: {
      type: [Number, String],
      required: false,
      default: null,
    },
    formData: {
      type: [Object],
      required: false,
      default: null,
    },
  },
  components: {
    EdInputText,
    EdInputMoney,
    EdInputDate
  },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>
